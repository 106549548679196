import "./UsersAddedToCart.css"
import React from 'react'

export default function UsersAddingCart() {


    const data1 = [
        {
            male: 0.8,
            female: 3.6,
            users_tried_proceed: 4.5
        }
    ]
    const data2 = [
        {
            male: 8,
            female: 26.6,
            users_tried_proceed: 35.5
        }
    ]
    const data3 = [
        {
            male: 5,
            female: 20,
            users_tried_proceed: 25.5
        }
    ]
    const data4 = [
        {
            male: 1,
            female: 5.9,
            users_tried_proceed: 7
        }
    ]
    return (
        <div>
            <div className="usersDontProceedSection">
                <div className="addCartHeader">
                    <b>Users Who Didn't Proceed After Adding To Cart</b>
                </div>
                <table className="notProceededUsersTable">
                    <tr className="usersNotProceededHeading">
                        <th colSpan="2">
                        </th>
                        <th>
                            Male Users
                        </th>
                        <th>
                            Female Users
                        </th>
                        <th>
                        </th>
                    </tr>
                    <tr className="notProceedDetails">
                        <td>
                            1
                        </td>
                        <td className="checkedDetails">
                            Checked out with some other product
                        </td>
                        {
                            data1.map(d => {
                                return <>
                                    <td>
                                        {d.male}%
                                    </td>
                                    <td>
                                        {d.female}%
                                    </td>
                                    <td colSpan="2">
                                        <div className="triedPercentage">
                                            {d.users_tried_proceed}%
                                        </div>
                                        <div className="totalProceed">
                                            <div className="userProceeded" style={{width:d.users_tried_proceed}}>

                                            </div>
                                        </div>
                                    </td>
                                </>
                            })
                        }
                    </tr>
                    <tr className="notProceedDetails">
                        <td>
                            2
                        </td>
                        <td className="checkedDetails">
                            Checked out with some other brands product
                        </td>
                        {
                            data2.map(d => {
                                return <>
                                    <td>
                                        {d.male}%
                                    </td>
                                    <td>
                                        {d.female}%
                                    </td>
                                    <td colSpan="2">
                                        <div className="triedPercentage">
                                            {d.users_tried_proceed}%
                                        </div>
                                        <div className="totalProceed">
                                            <div className="userProceeded" style={{width:d.users_tried_proceed}}>

                                            </div>
                                        </div>
                                    </td>
                                </>
                            })
                        }
                    </tr>
                    <tr className="notProceedDetails">
                        <td>
                            3
                        </td>
                        <td className="checkedDetails">
                            Checked out with similar product
                        </td>
                        {
                            data3.map(d => {
                                return <>
                                    <td>
                                        {d.male}%
                                    </td>
                                    <td>
                                        {d.female}%
                                    </td>
                                    <td colSpan="2">
                                        <div className="triedPercentage">
                                            {d.users_tried_proceed}%
                                        </div>
                                        <div className="totalProceed" >
                                            <div className="userProceeded" style={{width:d.users_tried_proceed}}>

                                            </div>
                                        </div>
                                    </td>
                                </>
                            })
                        }
                    </tr>
                    <tr className="notProceedDetails">
                        <td>
                            4
                        </td>
                        <td className="checkedDetails">
                            Moved it into wishlist
                        </td>
                        {
                            data4.map(d => {
                                return <>
                                    <td>
                                        {d.male}%
                                    </td>
                                    <td>
                                        {d.female}%
                                    </td>
                                    <td colSpan="2">
                                        <div className="triedPercentage">
                                            {d.users_tried_proceed}%
                                        </div>
                                        <div className="totalProceed">
                                            <div className="userProceeded" style={{width:d.users_tried_proceed}}>

                                            </div>
                                        </div>
                                    </td>
                                </>
                            })
                        }
                    </tr>
                </table>
            </div>
        </div>
    )
}

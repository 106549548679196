import axios from "axios";
import React, {useEffect, useState, useRef} from "react";
import * as d3 from "d3";
// import { chart } from "highcharts";
// import { setDatasets } from 'react-chartjs-2/dist/utils';

// let datas = []
import Chart from "chart.js/auto";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {Bar} from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);
const MaleVsFemale = (props) => {
  const brandId = props.brandId;
  const [datas, setDatas] = useState([]);
  const startDate = props.startDate;
  const endDate = props.endDate;

  const getMaleVsFemale = async () => {
    const response = await axios
      .get(
        `https://brand.smytten.com/brandsdashboard/master/brand/${brandId}/users_gender_seggrigation/?startDate=${startDate}&endDate=${endDate}`
      )
      .catch((err) => {
        console.log(err);
      });
    setDatas(response.data.map((dat) => dat));
    console.log("ggggggggg", response.data);
    // dispatch(setUsers(response.data));
  };
  useEffect(() => {
    getMaleVsFemale();
  }, [brandId, startDate, endDate]);


   const data1 = {
    labels: datas.map((text) => text.age_group),
    datasets: [
      {
        label: "Brand Average",
        data: datas.map((text) => text.male),
        backgroundColor: "rgb(56, 80, 180)",
      },
      {
        label: "Category Average",
        data: datas.map((text) => text.female),
        backgroundColor: "rgb(246, 69, 154)",
      },
    ],
  };

  // const d3Chart1 = useRef();

  // useEffect(() => {
  //   // Draw chart using the data and updated dimensions
  //   const tooldiv = d3
  //     .select("#d3Second")
  //     .append("div")
  //     .style("visibility", "hidden")
  //     .style("position", "absolute")
  //     // .style("background-color", "#ecf0f1")
  //     .style("background-color", "white")
  //     .style("border-radius", "4px")
  //     .style("color", "black")
  //     .style("padding", "1% 3%");

  //   DrawChart(datas, tooldiv);

  //   // DrawAnotherChart(sample,dimensions);
  // });

  // const margin = {top: 50, right: 30, bottom: 30, left: 60};

  // function DrawChart(data, tooldiv) {
  //   // console.log(dimensions.width, dimensions.height)

  //   const chartwidth =
  //     parseInt(d3.select("#d3Second").style("width")) -
  //     margin.left -
  //     margin.right;
  //   const chartheight =
  //     parseInt(d3.select("#d3Second").style("height")) -
  //     margin.top -
  //     margin.bottom;

  //   const svg = d3
  //     .select(d3Chart1.current)
  //     .attr("width", chartwidth + margin.left + margin.right)
  //     .attr("height", chartheight + margin.top + margin.bottom);
  //   // x scale
  //   const x = d3
  //     .scaleBand()
  //     .domain(d3.range(data.length))
  //     .range([margin.left, chartwidth + margin.right])
  //     .padding(0.2);
  //   svg
  //     .append("g")
  //     .attr("transform", "translate(0," + chartheight + ")")
  //     .call(
  //       d3
  //         .axisBottom(x)
  //         .tickFormat((i) => data[i].age_group)
  //         // .tickSizeOuter(0)
  //         .tickSize(-chartheight + 50)
  //     );
  //   const max1 = d3.max(data, function (d) {
  //     return d.male;
  //   });
  //   const max2 = d3.max(data, function (d) {
  //     return d.female;
  //   });
  //   var max3 = Math.max(max1, max2);
  //   // console.log(max3)

  //   console.log(data);

  //   const y = d3
  //     .scaleLinear()
  //     .domain([0, Math.ceil(max3)])
  //     .range([chartheight, margin.top]);
  //   svg
  //     .append("g")
  //     .attr("transform", "translate(" + margin.left + ",0)")
  //     .call(d3.axisLeft(y).tickSize(-chartwidth + 30));

  //   //   draw bars

  //   function charts(data, color, width, height, yPath, xPath, name) {
  //     svg
  //       .append("g")
  //       .attr("fill", color)
  //       .selectAll("rect")
  //       .data(data)
  //       .join("rect")
  //       .attr("x", xPath)
  //       .attr("y", yPath)
  //       .attr("height", height)
  //       .attr("width", width)
  //       .on("mouseover", (e, d) => {
  //         // return d.categoryAverage
  //         console.log(e);
  //         console.log(d);
  //         tooldiv
  //           .style("visibility", "visible")
  //           .attr("dy", "0em")
  //           .text(name + "  " + `${d.female}` + "%");
  //       })
  //       .on("mousemove", (e, d) => {
  //         tooldiv
  //           .style("top", e.pageY + 15 + "px")
  //           .style("left", e.pageX + 15 + "px");
  //       })
  //       .on("mouseout", () => {
  //         tooldiv.style("visibility", "hidden");
  //       });
  //   }
  //   function charts1(data, color, width, height, yPath, xPath, name) {
  //     svg
  //       .append("g")
  //       .attr("fill", color)
  //       .selectAll("rect")
  //       .data(data)
  //       .join("rect")
  //       .attr("x", xPath)
  //       .attr("y", yPath)
  //       .attr("height", height)
  //       .attr("width", width)
  //       .on("mouseover", (e, d) => {
  //         // return d.categoryAverage
  //         console.log(e);
  //         console.log(d);
  //         tooldiv
  //           .style("visibility", "visible")
  //           .attr("dy", "0em")
  //           .text(name + "  " + `${d.male}` + "%");
  //       })
  //       .on("mousemove", (e, d) => {
  //         tooldiv
  //           .style("top", e.pageY + 15 + "px")
  //           .style("left", e.pageX + 15 + "px");
  //       })
  //       .on("mouseout", () => {
  //         tooldiv.style("visibility", "hidden");
  //       });
  //   }
  //   charts(
  //     data,
  //     "#f6459a",
  //     x.bandwidth() / 2,
  //     (d) => y(0) - y(d.female),
  //     (d) => y(d.female),
  //     (d, i) => x(i) + x.bandwidth() / 2,
  //     "Female"
  //   );
  //   charts1(
  //     data,
  //     "#3850b4",
  //     x.bandwidth() / 2,
  //     (d) => y(0) - y(d.male),
  //     (d) => y(d.male),
  //     (d, i) => x(i),
  //     "Male"
  //   );
  //   svg
  //     .append("text")
  //     .attr("x", -(chartheight / 2))
  //     .attr("y", margin.left / 2.4)
  //     .attr("transform", "rotate(-90)")
  //     .attr("text-anchor", "middle")
  //     .attr("fill", "rgb(91, 92, 96)")
  //     .text("% Contribution");
  //   // svg
  //   //   .append("text")
  //   //   .attr("x", chartwidth / 2)
  //   //   .attr("y", chartheight + 60)
  //   //   // .attr()
  //   //   // .attr("text-anchor", "middle")
  //   //   .text("Female");

  //   // svg
  //   //   .append("text")
  //   //   .attr("x", chartwidth / 3)
  //   //   .attr("y", chartheight + 60)
  //   //   // .attr("text-anchor", "middle")
  //   //   .text("Male");
  //   svg
  //     .append("text")
  //     .attr("x", chartwidth / 1.8)
  //     .attr("y", chartheight + 40)
  //     .attr("text-anchor", "middle")
  //     .style("font-size", "14px")
  //     .attr("fill", "rgb(91, 92, 96)")
  //     .style("font-family", "sans-serif")
  //     .style("dominant-baseline", "hanging")
  //     .text("Age Group");
  //   // svg
  //   //   .append("text")
  //   //   .attr("x", chartwidth / 2)
  //   //   .attr("y", 20)
  //   //   .style("font-size", "18px")
  //   //   .style("font-weight","500")
  //   //   .style("text-transform","capitalize")
  //   //   .attr("text-anchor", "end")
  //   //   .text("Male Vs Female Users");
  // }

  return (
    <div className="map2">
      <div className="maleVsFemaleHeading">Male Vs Female Users</div>
      <div id="d3Second">
        {/* <svg ref={d3Chart1}></svg> */}
        <Bar
          data={data1}
          height={250}
          options={{
            plugins: {
              title: {
                display: true,
                text: "Age Group",
                position: "bottom",
              },
              legend: {
                display: false,
                position: "bottom",
              },
              datalabels:{
                display: false
              }
            },
          }}
        />
        <div className="chartFooter">
          <div className="label">
            <div className="labelBoxMale"></div>
            <div>Male</div>
          </div>
          <div className="label">
            <div className="labelBoxFemale"></div>
            <div>Female</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaleVsFemale;

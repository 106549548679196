import "./ProductTrial.css"
import React from 'react'
import ProductGeography from "../ProductGeograhy/ProductGeography"

export default function ProductTrials() {
    return (
        <div>

        <div className="mainTrailContainer">
            <div className="productSection1">
                <div className="trialSection">
                    <b>
                        TRAILS
                    </b>
                </div>
                <div className="productCard">
                    <div className="card">
                        <div className="productPercentage">
                            3.95%
                        </div>
                        <div className="productLabel">
                            Trail Rate
                        </div>
                    </div>
                    <div className="card">
                        <div className="productPercentage">
                            1.66
                        </div>
                        <div className="productLabel">
                            Trail Units
                        </div>

                    </div>
                    <div className="card">
                        <div className="productPercentage">
                            35.2K
                        </div>
                        <div className="productLabel">
                            Trail Volumes
                        </div>

                    </div>

                </div>
            </div>
            <div className="productSection2">
                <div className="adoptionSection">
                    <b>ADOPTION</b>
                </div>
                <div className="productCard">
                    <div className="card">
                        <div className="productPercentage">
                            3.95%
                        </div>
                        <div className="productLabel">
                            Trail Rate
                        </div>

                    </div>
                    <div className="card">
                        <div className="productPercentage">
                            2.05%
                        </div>
                        <div className="productLabel">
                            Repeat
                        </div>

                    </div>
                    <div className="card">
                        <div className="productPercentage">
                            1.06
                        </div>
                        <div className="productLabel">
                            Frequency
                        </div>

                    </div>
                    <div className="card">
                        <div className="productPercentage">
                            1.60
                        </div>
                        <div className="productLabel">
                            Repeat Units
                        </div>

                    </div>
                    <div className="card">
                        <div className="productPercentage">
                            800
                        </div>
                        <div className="productLabel">
                            Repeat Volumes
                        </div>

                    </div>

                </div>
            </div>
        </div>
        <ProductGeography/>
        </div>
    )
}

import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faHome,
  faThLarge,
  faTachometerAlt,
  faTvAlt,
  faCalendarAlt,
  faChartLine,
} from "@fortawesome/free-solid-svg-icons";
import {Link, NavLink} from "react-router-dom";


const Dashboard = () => {

  return (
    <div className="section">
      <div className="menu">
        <div className="menus">
          <FontAwesomeIcon className="home" icon={faHome} />
          <div>Home</div>
        </div>
        <div className="menus">
          <FontAwesomeIcon className="dashboard" icon={faThLarge} />
          <div>Dashboard</div>
        </div>
        <div className="menus">
          <FontAwesomeIcon className="request" icon={faTachometerAlt} />
          <div>Request Conversion</div>
        </div>
        <div className="overview">
          <div className="menus">
            <FontAwesomeIcon className="sampling" icon={faTvAlt} />
            <div>Sampling Overview</div>
          </div>
          <div className="samplingList">
            <NavLink className="clicks" to="/" style={{textDecoration: "none"}} >
              <p>Performance</p>
            </NavLink>
            <NavLink className="clicks"
              to="/consumer"
              style={{textDecoration: "none"}}  
            >
              <p>Consumer Profile</p>
            </NavLink>
            <NavLink to="/ratings" className="clicks"
              style={{textDecoration: "none"}}>
            <p>Ratings and Review</p>
            </NavLink>
            <NavLink to="/product" className="clicks" style={{textDecoration:"none"}}>
            <p>Product</p>
            </NavLink>
          </div>
        </div>
        <div className="menus">
          <FontAwesomeIcon className="survey" icon={faCalendarAlt} />
          <div>Request Survey</div>
        </div>
        <div className="menus">
          <FontAwesomeIcon className="chart" icon={faChartLine} />
          <div>Request Engagement</div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

import React, {useState, useEffect} from "react";

import Graph1 from "./Graph1";
import Graph2 from "./Graph2";
import Graph3 from "./Graph3";
import WeeklyValues from "./WeeklyValues";
import axios from "axios";
import "./RatingMetricBoxes.css"
import { RatingsAndReviewStackedGraph } from "../RatingsAndReviewGraphs/RatingsAndReviewStackedGraph";
// import Maps from "./Maps";

const boxData1 = [
  {
    category: "Users Engaged",
    // value: "48.6K",
    // percentage: "+5.0%",
    // weeklyValue: [20, 40, 10, 60, 80, 10, 36],
  },
];

function nFormatter(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
}

const RatingMetricBoxes = (props) => {
  let brandId = props.brandId;
  let startDate = props.startDate;
  let endDate = props.endDate;

  //  console.log(brandId)
  const [boxData, setBoxData] = useState([]);
  // const [boxData1, setBoxData1] = useState([]);
  const [boxData2, setBoxData2] = useState([]);
  const [boxData3, setBoxData3] = useState([]);

  const getSKUActivelySampled = async () => {
    const response = await axios
      .get(
        `https://brand.smytten.com/brandsdashboard/master/brand/${brandId}/sku_sampled/?startDate=${startDate}&endDate=${endDate}`
      )
      .catch((err) => {
        console.log(err);
      });
    setBoxData(response.data.map((dat) => dat));
    console.log(
      "products",
      response.data.map((dat) => dat)
    );
    console.log(setBoxData);
    
  };
  // const getUsersEngaged = async () => {
  //   const response = await axios
  //     .get(
  //       `https://brand.smytten.com/brandsdashboard/master/brand/${brandId}/users_engaged/?startDate=2022-04-22&endDate=2022-04-28`
  //     )
  //     .catch((err) => {
  //       console.log(err);
  //     });
  //   setBoxData1(response.data.map((dat) => dat));
  //   console.log(
  //     "products",
  //     response.data.map((dat) => dat)
  //   );

  // };
  const getUsersTried = async () => {
    const response = await axios
      .get(
        `https://brand.smytten.com/brandsdashboard/master/brand/${brandId}/users_tried/?startDate=${startDate}&endDate=${endDate}`
      )
      .catch((err) => {
        console.log(err);
      });
    setBoxData2(response.data.map((dat) => dat));
    console.log(
      "products",
      response.data.map((dat) => dat)
    );
    
  };
  const getPincodeServed = async () => {
    const response = await axios
      .get(
        `https://brand.smytten.com/brandsdashboard/master/brand/${brandId}/pincodes_serviced/?startDate=${startDate}&endDate=${endDate}`
      )
      .catch((err) => {
        console.log(err);
      });
    setBoxData3(response.data.map((dat) => dat));
    console.log(
      "products",
      response.data.map((dat) => dat)
    );
    
  };
  useEffect(() => {
    getSKUActivelySampled();
    getUsersTried();
    getPincodeServed();
  }, [brandId, startDate, endDate]);
  let format = /[-]/;

  var value = document.querySelector(".percentage");
  var value1 = document.querySelector(".percentage1");
  var value2 = document.querySelector(".percentage2");

  if (value && value1 && value2) {
    let rs = format.test(value.textContent);
    if (rs === true) {
      value.style.color = "rgb(255, 115, 97)";
    } else {
      value.style.color = "rgb(0, 188, 138)";
    }
    let rs1 = format.test(value1.textContent);
    if (rs1 === true) {
      value1.style.color = "rgb(255, 115, 97)";
    } else {
      value1.style.color = "rgb(0, 188, 138)";
    }
    let rs2 = format.test(value2.textContent);
    if (rs2 === true) {
      value2.style.color = "rgb(255, 115, 97)";
    } else {
      value2.style.color = "rgb(0, 188, 138)";
    }
  }


  return (
    <div className="boxes">
      <div className="show">Showing | All Categories | All Products</div>
      <div className="metricBoxes">
        {boxData.map((data, index) => {
          return (
            <div className="box" key={index}>
              <div className="dataCategory">
                <p>Average NPS Rating (9413)</p>
              </div>
              <div className="value">
                <div className="det">
                 <b>
                  {data.metric_value}
                 </b>
                  <p className="percentage">
                    {data.percentage_change.toFixed(1).toString()}%
                  </p>
                </div>
                <div className="graph" id={index}>
                  <WeeklyValues
                    val={data.weekly_numbers.slice(0, 7)}
                    id={index}
                    colorData={data.percentage_change.toFixed(1).toString()}
                    brandId={props.brandId}
                    startDate={props.startDate}
                    endDate={props.endDate}
                  />
                </div>
              </div>
            </div>
          );
        })}
        {boxData1.map((data, index) => {
          return (
            <div className="box" key={index}>
              <div className="dataCategory">
                <p>Average Product Ratings (9413)</p>
              </div>
              {/* <div className="value">
                <div className="det">
                  <h2>{nFormatter(data.metric_value)}</h2>
                  <p>{data.percentage_change.toFixed(1)}%</p>
                </div>
                <div className="graph" id={index}>
                  <Graph1
                    val={data.weekly_numbers.slice(0, 7)}
                    id={index}
                    colorData={data.percentage_change.toFixed(1).toString()}
                    brandId={props.brandId}
                  />
                </div>
              </div>{" "} */}
            </div>
          );
        })}
        {boxData2.map((data, index) => {
          return (
            <div className="box" key={index}>
              <div className="dataCategory">
                <p>Positive Feedback</p>
              </div>
              <div className="value">
                <div className="det">
                 <b>

                  {nFormatter(data.metric_value)}
                 </b>
                  <p className="percentage1">
                    {data.percentage_change.toFixed(1).toString()}%
                  </p>
                </div>
                <div className="graph" id={index}>
                  <Graph2
                    val={data.weekly_numbers.slice(0, 7)}
                    id={index}
                    colorData={data.percentage_change.toFixed(1).toString()}
                    brandId={props.brandId}
                    startDate={props.startDate}
                    endDate={props.endDate}
                  />
                </div>
              </div>
            </div>
          );
        })}
        {boxData3.map((data, index) => {
          return (
            <div className="box" key={index}>
              <div className="dataCategory">
                <p>Negative Feedback</p>
              </div>
              <div className="value">
                <div className="det">
                 <b>
                  {nFormatter(data.metric_value)}
                 </b>
                  <p className="percentage2">
                    {data.percentage_change.toFixed(1).toString()}%
                  </p>
                </div>
                <div className="graph">
                  <Graph3
                    val={data.weekly_numbers.slice(0, 7)}
                    id={index}
                    colorData={data.percentage_change.toFixed(1).toString()}
                    brandId={props.brandId}
                    startDate={props.startDate}
                    endDate={props.endDate}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div className="ratingsGraphs">
      <RatingsAndReviewStackedGraph/>
      </div>
    </div>
  );
};

export default RatingMetricBoxes;

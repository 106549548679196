import React, {useState, useEffect} from "react";
import axios from "axios";
import Chart from "chart.js/auto";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {Bar} from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const BrandVsCategory = (props) => {
  const brandId = props.brandId;
  const startDate = props.startDate;
  const endDate = props.endDate;
  const [sample, setSample] = useState([]);

  const getBrandsVsCategory = async () => {
    const response = await axios
      .get(
        `https://brand.smytten.com/brandsdashboard/master/brand/${brandId}/users_tried_your_product/?startDate=${startDate}&endDate=${endDate}`
      )
      .catch((err) => {
        console.log(err);
      });
    setSample(response.data.map((dat) => dat));
    console.log(
      "brandsvscategory",
      response.data.map((dat) => dat)
    );
    // dispatch(setUsers(response.data));
  };

  useEffect(() => {
    getBrandsVsCategory();
  }, [brandId, startDate, endDate]);

  const datas = {
    labels: sample.map((text) => text.age_group),
    datasets: [
      {
        label: "Brand Average",
        data: sample.map((text) => text.brands_average),
        backgroundColor: "rgb(166, 206, 227)",
      },
      {
        label: "Category Average",
        data: sample.map((text) => text.category_average),
        backgroundColor: "rgb(0, 48, 135)",
      },
    ],
  };

  return (
    <div className="map1">
      <div className="brandVsCategoryHeading">Users Tried your product</div>
      <div id="d3demo">
        <div className="brandVsCategoryBarGraph">
        <Bar
          data={datas}
          height={250}
          options={{
            plugins: {
              title: {
                display: true,
                text: "Age Group",
                position: "bottom",
              },
              legend: {
                display: false,
                position: "bottom",
              },
              datalabels:{
                display: false
              }
            },
          }}
        />
        </div> 
        <div className="chartFooter">
          <div className="label">
            <div className="labelBoxBrands"></div>
            <div>Brands Average</div>
          </div>
          <div className="label">
            <div className="labelBoxCategories"></div>
            <div>Category Average</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandVsCategory;

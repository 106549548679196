import { useEffect, useState } from "react";
import { Chart as ChartJs, Tooltip, Title, ArcElement, Legend } from "chart.js";
import { Doughnut, Pie } from "react-chartjs-2";
import "./ConsumerPieChart.css";
import UsersAddingCart from "./UsersDontProceed/UsersAddingCart";
ChartJs.register(Tooltip, Title, ArcElement, Legend);

// const data = {
//   datasets: [
//     
//       data: [10, 20, 30],
//       backgroundColor: ["red", "blue", "yellow"],
//     },
//   ],
//   // These labels appear in the legend and in the tooltips when hovering different arcs
//   labels: ["Red", "Yellow", "Blue"],
// };
function ConsumerPieChart() {
  const graphData = [
    {
      name: "Skin types",
      count: 41.16,
    },
    {
      name: "Mamaearth Vitamin",
      count: 26.91,
    },
    {
      name: "Mineral Oil",
      count: 25.07,
    },
    {
      name: "Face Wash",
      count: 24.42,
    },
    {
      name: "Mineral Oils",
      count: 20.2,
    },

    {
      name: "Hair Fall",
      count: 19.26,
    },
    {
      name: "Onion Oil",
      count: 15.98,
    },
    {
      name: "Collagen Production",
      count: 15.48,
    },
    {
      name: "Sulphur Potassium",
      count: 14.89,
    },
    {
      name: "Moisture Controls",
      count: 14.88,
    },
  ];
  const [graph, setGraph] = useState({
    labels: [],
    data: [],
  });

  useEffect(() => {
    const labels = [];
    const data = [];

    graphData.map((v) => {
      labels.push(v.name);
      data.push(v.count);
    });

    setGraph({
      labels: labels,
      data: data,
    });
  }, []);

  const data = {
    labels: graph.labels,
    datasets: [
      {
        label: "# of Votes",
        data: graph.data,
        backgroundColor: [
          "rgb(0, 48, 135)",
          "rgb(208, 235, 249)",
          "rgb(19, 115, 93)",
          "rgb(31, 120, 180)",
          "rgb(136, 187, 145)",
          "rgb(255, 207, 97)",
          "rgb(139, 47, 138)",
          "rgb(247, 110, 32)",
          "rgb(255, 115, 97)",
          "rgb(190, 37, 37)",
        ],
        // borderColor: [
        //   'rgba(255, 99, 132, 1)',
        //   'rgba(54, 162, 235, 1)',
        //   'rgba(255, 206, 86, 1)',
        //   'rgba(75, 192, 192, 1)',
        //   'rgba(153, 102, 255, 1)',
        //   'rgba(255, 159, 64, 1)',
        // ],
        borderWidth: "",
      },
    ],
  };

  return (
    <div>

    <div className="pie">
      <div className="pieChartHeading">
        <b>Trends Users who Tried your product are Interested in</b>
      </div>
      <div className="pieChartDetails">
        <div className="ConsumerPieChart" >
          <Pie
            data={data}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
                datalabels: {
                  display: false,
                },
              },
            }}
          />
        </div>
        <div className="pieData">
          {graphData.map((datas, index) => {
            return (
              <div className="pieDetails" key={index}>
                <div className="name">
                  <span>{index + 1}</span>
                  <p>{datas.name}</p>
                </div>
                <div className="colorBoxValues">
                  <span className={"colorBox" + index}></span>
                  <h6>{datas.count + "%"}</h6>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
    <UsersAddingCart/>
    </div>
  );
}

export default ConsumerPieChart;

import React, { useState, useEffect } from "react";
import axios from "axios";

import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import ProductCollections from "./ProductCollectionDetails/ProductCollections";
// import RatingMetricBoxes from "./RatingMetricBoxes/RatingMetricBoxes";
const ProductDetails = (props) => {
    let startDate = localStorage.getItem("start_date");
    let endDate = localStorage.getItem("end_date");
    let range = {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "This Month": [moment().startOf("month"), moment().endOf("month")],
        "Last Month": [
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month"),
        ],
        "Last Year": [
            moment().subtract(1, "year").startOf("year"),
            moment().subtract(1, "year").endOf("year"),
        ],
    };

    const changeDateHandler = (e) => {
        console.log("clicked");
        let x = document.getElementsByClassName("applyBtn");
        console.log(x);
        x[0].addEventListener("click", (e) => {
            let startValue = document
                .getElementsByClassName("drp-selected")[0]
                .textContent.slice(0, 10);
            let endValue = document
                .getElementsByClassName("drp-selected")[0]
                .textContent.slice(13);

            console.log(startValue.slice(0, 2));

            let startMonth = startValue.slice(0, 2);
            let startDate = startValue.slice(3, 5);
            let startYear = startValue.slice(6);

            let endDate = endValue.slice(3, 5);
            let endYear = endValue.slice(6);
            let endMonth = endValue.slice(0, 2);

            console.log(`${startYear}-${startMonth}-${startDate}`);
            localStorage.setItem(
                "start_date",
                `${startYear}-${startMonth}-${startDate}`
            );
            localStorage.setItem("end_date", `${endYear}-${endMonth}-${endDate}`);
            console.log("new start date", startValue);
            console.log("new end date", endValue);
            window.location.reload();
        });
    };

    //  date range picker ends

    const [brandId, setBrandId] = useState();
    let brand = props.brandId;

    if (brandId !== brand) {
        setBrandId(brand);
    }
    let forward = ">";
    const [state, setState] = useState(false);
    const [productState, setProductState] = useState(false);
    const [isCheck, setIsCheck] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const clickHandler = () => {
        state ? setState(false) : setState(true);
        console.log(state);
    };
    const productClickHandler = () => {
        productState ? setProductState(false) : setProductState(true);
    };
    const handleOnChange1 = () => {
        setIsChecked(!isChecked);
    };
    const [productList, setProductList] = useState([]);
    const [checkedState, setCheckedState] = useState(new Array(100).fill(false));
    const handleOnChange = () => {
        setIsCheck(!isCheck);
    };
    const handleChange = (position) => {
        const updatedCheckedState = checkedState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedState(updatedCheckedState);
        console.log(checkedState);
    };
    const [categoryList, setCategoryList] = useState([]);

    const getCategories = async () => {
        const response = await axios
            .get(
                `https://brand.smytten.com/brandsdashboard/master/brand/${brandId}/categories/`
            )
            .catch((err) => {
                console.log(err);
            });
        setCategoryList(response.data.map((dat) => dat.categoryName));
        console.log(
            "categories",
            response.data.map((dat) => dat)
        );
        console.log(categoryList);
    };
    const getProducts = async () => {
        const response = await axios
            .get(
                `https://brand.smytten.com/brandsdashboard/master/brand/${brandId}/products/?startDate=2022-04-21&endDate=2022-04-28`
            )
            .catch((err) => {
                console.log(err);
            });
        setProductList(response.data.map((dat) => dat.categoryName));
        console.log(
            "products",
            response.data.map((dat) => dat)
        );
    };
    useEffect(() => {
        getCategories();
        getProducts();
    }, [brandId]);
    const [checkedCategoryState, setCheckedCategoryState] = useState(
        new Array(52).fill(false)
    );

    const handleCategoryChange = (position) => {
        const updatedCheckedState = checkedCategoryState.map((item, index) =>
            index === position ? !item : item
        );
        setCheckedCategoryState(updatedCheckedState);
        console.log(checkedCategoryState);
    };
    return (
        <div className="mainCategoryPage">
            <div className="catData">
                <div className="home">
                    Home {forward} <p>Product</p>
                </div>
                {/* <div id="homeHeader"> */}
                <div className="sample">
                    <div>
                        <h2>
                            <b>Product
                            </b>
                        </h2>
                    </div>
                    <div></div>
                    <div className="category">
                        <div className="first">
                            <p className="catSection1" onClick={clickHandler}>
                                Category
                            </p>
                            {state ? (
                                <div className="mainCategory1">
                                    <input
                                        className="search"
                                        type="text"
                                        placeholder="search"
                                    ></input>
                                    <div className="cList">
                                        <input
                                            type="checkbox"
                                            checked={isChecked}
                                            onChange={handleOnChange1}
                                        ></input>
                                        <p>All</p>
                                    </div>
                                    {categoryList.map((d, index) => {
                                        return (
                                            <div className="categoryList">
                                                <input
                                                    type="checkbox"
                                                    checked={checkedCategoryState[index] || isChecked}
                                                    onChange={() => handleCategoryChange(index)}
                                                ></input>
                                                <p>{d}</p>
                                            </div>
                                        );
                                    })}
                                </div>
                            ) : null}
                        </div>
                        <div className="second">
                            <p className="catSection2" onClick={productClickHandler}>
                                Product
                            </p>
                            {productState ? (
                                <div className="mainCategory2">
                                    <input
                                        className="search"
                                        type="text"
                                        placeholder="search"
                                    ></input>
                                    <div className="pList">
                                        <input
                                            type="checkbox"
                                            checked={isCheck}
                                            onChange={handleOnChange}
                                        ></input>
                                        <p>All</p>
                                    </div>
                                    <div className="totalList">
                                        {productList.map((d, index) => {
                                            return (
                                                <div className="productList">
                                                    <input
                                                        type="checkbox"
                                                        checked={checkedState[index] || isCheck}
                                                        onChange={() => handleChange(index)}
                                                    ></input>
                                                    <p>{d}</p>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className="DateRange">
                            <DateRangePicker ranges={range}>
                                <button id="date" onClick={changeDateHandler}>
                                    {startDate} to {endDate}
                                </button>
                            </DateRangePicker>
                        </div>
                    </div>
                </div>
            </div>
            <ProductCollections brandId={props.brandId}
                startDate={startDate}
                endDate={endDate} />
        </div>
    );
};

export default ProductDetails;

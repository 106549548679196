import "./ProductGender.css"
import React from 'react'

export default function ProductGender() {
    const genderHeadings = ['Trial Rate', 'Trial Units', 'Trial Rate', 'Repeat', 'Frequency', 'Repeat Unit']
    const data1 = [
        {
            name: "Male",
            trial_rate: 0.5579,
            trial_units: 1.67,
            repeat: 1.56,
            frequency: 1.06,
            repeat_unit: 1.88,

        },
    ];
    const data2 = [
        
        {
            name: "Female",
            trial_rate: 1.21,
            trial_units: 1.66,
            repeat: 2.18,
            frequency: 1.06,
            repeat_unit: 1.7,

        },
        
    ]
    const data3 = [
       
        {
            name: "All",
            trial_rate: 0.8766,
            trial_units: 1.75,
            repeat: 2.06,
            frequency: 1.07,
            repeat_unit: 1.88,
        },
    ]
    const data4 = [
        {
            name: "Male",
            trial_rate: 0.1420,
            trial_units: 1.67,
            repeat: 1.56,
            frequency: 1.06,
            repeat_unit: 1.88,

        },
        {
            name: "Female",
            trial_rate: 0.3757,
            trial_units: 1.66,
            repeat: 2.18,
            frequency: 1.06,
            repeat_unit: 1.7,

        },
        {
            name: "All",
            trial_rate: 0.5321,
            trial_units: 1.75,
            repeat: 2.06,
            frequency: 1.07,
            repeat_unit: 1.88,
        },
    ]
    return (
        <div>
            <div className="genderSection">
                <div className="genderHeading">
                    Gender
                </div>
                <table className="genderTable">
                    <tr className="productGenderHeadings">
                        <th colSpan="2">
                            Age
                        </th>
                        <th colSpan="2">
                            Trails
                        </th>
                        <th colSpan="4">
                            Adoption
                        </th>
                    </tr>
                    <tr className="productGenderSections">
                        <td>

                        </td>
                        <td>

                        </td>
                        {
                            genderHeadings.map(d => {
                                return <>
                                    <td>
                                        {d}
                                    </td>
                                </>
                            })
                        }

                    </tr>
                    <tr className="ageWiseDetails">
                        <td className="ageGroup" rowSpan="3">
                            18-21 YEARS
                        </td>
                        {
                            data1.map(d => {
                                return <>
                                    <td>
                                        {d.name}
                                    </td>
                                    <td>
                                        {d.trial_rate}%
                                    </td>
                                    <td>
                                        {d.trial_units}
                                    </td>
                                    <td>
                                        {d.trial_rate}%
                                    </td>
                                    <td>
                                        {d.repeat}%
                                    </td>
                                    <td>
                                        {d.frequency}
                                    </td>
                                    <td>
                                        {d.repeat_unit}
                                    </td>
                                </>
                            })
                        }
                        </tr>
                        <tr className="ageWiseDetails1">
                        {
                            data2.map(d => {
                                return <>
                                    <td>
                                        {d.name}
                                    </td>
                                    <td>
                                        {d.trial_rate}%
                                    </td>
                                    <td>
                                        {d.trial_units}
                                    </td>
                                    <td>
                                        {d.trial_rate}%
                                    </td>
                                    <td>
                                        {d.repeat}%
                                    </td>
                                    <td>
                                        {d.frequency}
                                    </td>
                                    <td>
                                        {d.repeat_unit}
                                    </td>
                                </>
                            })
                        }
                        </tr>
                        <tr className="ageWiseDetails1">
                            
                        {
                            data3.map(d => {
                                return <>
                                    <td>
                                        {d.name}
                                    </td>
                                    <td>
                                        {d.trial_rate}%
                                    </td>
                                    <td>
                                        {d.trial_units}
                                    </td>
                                    <td>
                                        {d.trial_rate}%
                                    </td>
                                    <td>
                                        {d.repeat}%
                                    </td>
                                    <td>
                                        {d.frequency}
                                    </td>
                                    <td>
                                        {d.repeat_unit}
                                    </td>
                                </>
                            })
                        }
                       
                    </tr>
                    <tr className="ageWiseDetails">
                        <td className="ageGroup" rowSpan="3">
                            22-27 YEARS
                        </td>
                        {
                            data1.map(d => {
                                return <>
                                    <td>
                                        {d.name}
                                    </td>
                                    <td>
                                        {d.trial_rate}%
                                    </td>
                                    <td>
                                        {d.trial_units}
                                    </td>
                                    <td>
                                        {d.trial_rate}%
                                    </td>
                                    <td>
                                        {d.repeat}%
                                    </td>
                                    <td>
                                        {d.frequency}
                                    </td>
                                    <td>
                                        {d.repeat_unit}
                                    </td>
                                </>
                            })
                        }
                        </tr>
                        <tr className="ageWiseDetails1">

                        {
                            data2.map(d => {
                                return <>
                                    <td>
                                        {d.name}
                                    </td>
                                    <td>
                                        {d.trial_rate}%
                                    </td>
                                    <td>
                                        {d.trial_units}
                                    </td>
                                    <td>
                                        {d.trial_rate}%
                                    </td>
                                    <td>
                                        {d.repeat}%
                                    </td>
                                    <td>
                                        {d.frequency}
                                    </td>
                                    <td>
                                        {d.repeat_unit}
                                    </td>
                                </>
                            })
                        }
                        </tr>
                        <tr className="ageWiseDetails1">
                        {
                            data3.map(d => {
                                return <>
                                    <td>
                                        {d.name}
                                    </td>
                                    <td>
                                        {d.trial_rate}%
                                    </td>
                                    <td>
                                        {d.trial_units}
                                    </td>
                                    <td>
                                        {d.trial_rate}%
                                    </td>
                                    <td>
                                        {d.repeat}%
                                    </td>
                                    <td>
                                        {d.frequency}
                                    </td>
                                    <td>
                                        {d.repeat_unit}
                                    </td>
                                </>
                            })
                        }
                    </tr>
                    <tr className="ageWiseDetails">
                        <td className="ageGroup" rowSpan="3">
                            28-35 YEARS
                        </td>
                        {
                            data1.map(d => {
                                return <>
                                    <td>
                                        {d.name}
                                    </td>
                                    <td>
                                        {d.trial_rate}%
                                    </td>
                                    <td>
                                        {d.trial_units}
                                    </td>
                                    <td>
                                        {d.trial_rate}%
                                    </td>
                                    <td>
                                        {d.repeat}%
                                    </td>
                                    <td>
                                        {d.frequency}
                                    </td>
                                    <td>
                                        {d.repeat_unit}
                                    </td>
                                </>
                            })
                        }
                        </tr>
                        <tr className="ageWiseDetails1">
                        {
                            data2.map(d => {
                                return <>
                                    <td>
                                        {d.name}
                                    </td>
                                    <td>
                                        {d.trial_rate}%
                                    </td>
                                    <td>
                                        {d.trial_units}
                                    </td>
                                    <td>
                                        {d.trial_rate}%
                                    </td>
                                    <td>
                                        {d.repeat}%
                                    </td>
                                    <td>
                                        {d.frequency}
                                    </td>
                                    <td>
                                        {d.repeat_unit}
                                    </td>
                                </>
                            })
                        }
                        </tr>
                        <tr className="ageWiseDetails1">
                        {
                            data3.map(d => {
                                return <>
                                    <td>
                                        {d.name}
                                    </td>
                                    <td>
                                        {d.trial_rate}%
                                    </td>
                                    <td>
                                        {d.trial_units}
                                    </td>
                                    <td>
                                        {d.trial_rate}%
                                    </td>
                                    <td>
                                        {d.repeat}%
                                    </td>
                                    <td>
                                        {d.frequency}
                                    </td>
                                    <td>
                                        {d.repeat_unit}
                                    </td>
                                </>
                            })
                        }
                    </tr>
                    <tr className="ageWiseDetails">
                        <td className="ageGroup" rowSpan="3">
                            35+ YEARS
                        </td>
                        {
                            data1.map(d => {
                                return <>
                                    <td>
                                        {d.name}
                                    </td>
                                    <td>
                                        {d.trial_rate}%
                                    </td>
                                    <td>
                                        {d.trial_units}
                                    </td>
                                    <td>
                                        {d.trial_rate}%
                                    </td>
                                    <td>
                                        {d.repeat}%
                                    </td>
                                    <td>
                                        {d.frequency}
                                    </td>
                                    <td>
                                        {d.repeat_unit}
                                    </td>
                                </>
                            })
                        }
                        </tr>
                        <tr className="ageWiseDetails1">
                        {
                            data2.map(d => {
                                return <>
                                    <td>
                                        {d.name}
                                    </td>
                                    <td>
                                        {d.trial_rate}%
                                    </td>
                                    <td>
                                        {d.trial_units}
                                    </td>
                                    <td>
                                        {d.trial_rate}%
                                    </td>
                                    <td>
                                        {d.repeat}%
                                    </td>
                                    <td>
                                        {d.frequency}
                                    </td>
                                    <td>
                                        {d.repeat_unit}
                                    </td>
                                </>
                            })
                        }
                        </tr>
                        <tr className="ageWiseDetails1">
                        {
                            data3.map(d => {
                                return <>
                                    <td>
                                        {d.name}
                                    </td>
                                    <td>
                                        {d.trial_rate}%
                                    </td>
                                    <td>
                                        {d.trial_units}
                                    </td>
                                    <td>
                                        {d.trial_rate}%
                                    </td>
                                    <td>
                                        {d.repeat}%
                                    </td>
                                    <td>
                                        {d.frequency}
                                    </td>
                                    <td>
                                        {d.repeat_unit}
                                    </td>
                                </>
                            })
                        }
                    </tr>

                </table>
            </div>
        </div>
    )
}

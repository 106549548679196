import "./App.css";
import Dashboard from "./components/Dashboard";
import Header from "./components/Header";
import {BrowserRouter, Routes, Route} from "react-router-dom";
import ConsumerHeader from "./components/consumerProfile/ConsumerHeader";
import RatingsAndReviewHeader from "./components/RatingsAndReview/RatingsAndReviewHeader";
import ProductHeader from "./components/product/ProductHeader";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
    <div className="main">
      {/* <Header /> */}
    </div>
      <Dashboard />
        <Routes>
        <Route path="/" element={<Header/>}/>
        <Route path="/consumer" element={<ConsumerHeader/>}/>
        <Route path="/ratings" element={<RatingsAndReviewHeader/>}/>
        <Route path="/product" element={<ProductHeader/>}/>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;

import React, {useState, useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBell} from "@fortawesome/free-regular-svg-icons";
import {
  faBars,
  faGear,
  faComments,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import RatingsDetails from "./RatingsDetails"
let currentId;
const RatingsAndReviewHeader = () => {
  let brand = localStorage.getItem("brand_id");
  const [brands, setBrands] = useState([]);

  const getBrands = async () => {
    const response = await axios
      .get(`https://brand.smytten.com/brandsdashboard/master/brands/`)
      .catch((err) => {
        console.log(err);
      });
    console.log("ggggggggg", response.data);
    setBrands(response.data.map((dat) => dat));
  };
  useEffect(() => {
    getBrands();
  }, []);
  const [open, setOpen] = useState(false);
  const clickHandler = () => {
    setOpen(!open);
  };

  const changeHandler = (e) => {
    currentId = e.target.value;
    console.log(currentId);
    localStorage.setItem("brand_id", currentId);
    window.location.reload();
  };
  const MakeItem = function (X) {
    return (
      <option key={X.brandId} value={X.brandId}>
        {X.brandName}
      </option>
    );
  };

  return (
    <div>
      <div className="header">
        <div>
          <FontAwesomeIcon
            className="hamberger"
            icon={faBars}
            onClick={clickHandler}
            aria-controls="example-collapse-text"
            aria-expanded={open}
          />
        </div>
        <div className="headerOne">
          <div className="headerCenter">
            <FontAwesomeIcon className="settings" icon={faGear} />
            <FontAwesomeIcon className="chat" icon={faComments} />
            <FontAwesomeIcon className="notification" icon={faBell} />
            <div>
              <select
                className="selectBrand"
                value={brand}
                onChange={(e) => {
                  changeHandler(e);
                }}
              >
                {brands.map(MakeItem)}
              </select>
            </div>
          </div>
          <div className="user">
            <FontAwesomeIcon className="userProfile" icon={faCircle} />
            <div>Smytten</div>
            <img
              src="https://cdn4.iconfinder.com/data/icons/podcast-collection/100/caret-512.png"
              width="50px"
              height="50px"
              alt="user"
            />
          </div>
        </div>
      </div>
      <div className="data">
        <RatingsDetails brandId={brand} />
      </div>
    </div>
  );
};

export default RatingsAndReviewHeader;

import React, {useEffect, useRef, useState, useLayoutEffect} from "react";
import * as d3 from "d3";
import axios from "axios";

const Graph2 = (props) => {
  let brandId = props.brandId
  let bar_values = props.val
    let startDate = props.startDate;
  let endDate = props.endDate;
  const [boxData2, setBoxData2] = useState();
 

  
  let string = props.colorData;

  // console.log(parseInt(string))

  let format = /[-]/;

  var t = (
    <div className="miniGraph2" id={props.id}>
      <svg className="svg-canvas2" width="250px" height="40px" />
    </div>
  );

  const getUsersTried = async () => {
    const response = await axios
      .get(
        `https://brand.smytten.com/brandsdashboard/master/brand/${brandId}/users_tried/?startDate=${startDate}&endDate=${endDate}`
      )
      .catch((err) => {
        console.log(err);
      });
    setBoxData2(response.data.map((dat) => dat[0].weekly_numbers));
    console.log(
      "products efbaersbfebrs",
      response.data.map((dat) => dat[0].weekly_numbers)
    );
    // dispatch(setUsers(response.data));
  };

  useEffect(()=>{
    getUsersTried()
  },[brandId,startDate,endDate])

  useEffect(() => {
    drawGraph(bar_values)
  },[bar_values]);
  function drawGraph (bar_values) {
    var canvas_width = parseInt(d3.select(".miniGraph2").style("width"));
    var canvas_height = parseInt(d3.select(".miniGraph2").style("height"));
  
    var margin = 0.05 * canvas_width;
  
    var graph_width = canvas_width + margin;
    var graph_height = canvas_height - 2 * margin;
  
    var scale = d3
      .scaleLinear()
      .domain([Math.min(...bar_values), Math.max(...bar_values)])
      .range([graph_height / 2, graph_height / 1.3]);
  
    var partition_size = graph_width / bar_values.length;
  
    const svg = d3.selectAll(".svg-canvas2");
    // svg.selectAll("svg").remove();
  
    var bars = svg
      .selectAll("rect")
      .data(bar_values)
      .enter()
      .append("g")
      .attr("transform", function (d, i) {
        return `translate(${partition_size * i}, 0)`;
      });
    let rs = format.test(string);
  
    if (rs === true) {
      bars
        .append("rect")
        .attr("height", function (d) {
          return scale(d);
        })
        .attr("width", 9)
        .style("fill", "rgb(255, 115, 97)")
        .attr("y", function (d) {
          return graph_height - scale(d);
        });
    } else {
      bars
        .append("rect")
        .attr("height", function (d) {
          return scale(d);
        })
        .attr("width", 9)
        .style("fill", "rgb(0, 188, 138)")
        .attr("y", function (d) {
          return graph_height - scale(d);
        });
    }
  }
  return t;
};

export default Graph2;

import React, {useState, useEffect} from "react";
import ProductTrials from "../ProductTrial/ProductTrials";
import "./ProductCollection.css"


const ProductCollections = (props) => {

  return (
    <div className="boxes">
      <div className="show">Showing | All Categories | All Products</div>
      <div className="collectionSection">
          <div className="collection"> 
          Collection
          </div>
          <div className="collection">
          Products
          </div>
      </div>
      <ProductTrials/>
    </div>
  );
};

export default ProductCollections;

import React, {useEffect,useState} from "react";
import * as d3 from "d3";
// import {range} from "d3";

import axios from "axios";

function WeeklyValues(props) {
  // if(!props.id) return
  let bar_values = props.val;
  let brandId = props.brandId
    let startDate = props.startDate;
  let endDate = props.endDate;
  const [boxData, setBoxData] = useState([]);
  // const [boxData1, setBoxData1] = useState([]);
  

  const getSKUActivelySampled = async () => {
    const response = await axios
      .get(
        `https://brand.smytten.com/brandsdashboard/master/brand/${brandId}/sku_sampled/?startDate=${startDate}&endDate=${endDate}`
      )
      .catch((err) => {
        console.log(err);
      });
    setBoxData(response.data.map((dat) => dat));
    console.log(
      "products",
      response.data.map((dat) => dat)
    );
    console.log(setBoxData);
    // dispatch(setUsers(response.data));
  };
  
  useEffect(() => {
    getSKUActivelySampled();
  }, [brandId,startDate,endDate]);


 
  let string = props.colorData

  // console.log(parseInt(string))

  let format = /[-]/ ;

  var t = (
    <div className="miniGraph" id={props.id}>
      <svg className="svg-canvas" width="250px" height="40px" />
    </div>
  );

  useEffect(() => {
    var canvas_width = parseInt(d3.select(".miniGraph").style("width"));
    var canvas_height = parseInt(d3.select(".miniGraph").style("height"));

    var margin = 0.05 * canvas_width;

    var graph_width = canvas_width + margin;
    var graph_height = canvas_height - 2 * margin;

    var scale = d3
      .scaleLinear()
      .domain([Math.min(...bar_values), Math.max(...bar_values)])
      .range([graph_height / 2, graph_height/1.3]);

    var partition_size = (graph_width / bar_values.length);

    const svg = d3.selectAll(".svg-canvas");
    // svg.selectAll("svg").remove();

    var bars = svg
      .selectAll("rect")
      .data(bar_values)
      .enter()
      .append("g")
      .attr("transform", function (d, i) {
        return `translate(${partition_size * i}, 0)`;
      });
    let rs = format.test(string)

    if (rs===true){
      
    bars
      .append("rect")
      .attr("height", function (d) {
        return scale(d);
      })
      .attr("width", 9)
      .style("fill", "rgb(255, 115, 97)")
      .attr("y", function (d) {
        return graph_height - scale(d);
      });
    }
    else{
      
      bars
      .append("rect")
      .attr("height", function (d) {
        return scale(d);
      })
      .attr("width", 9)
      .style("fill", "rgb(0, 188, 138)")
      .attr("y", function (d) {
        return graph_height - scale(d);
      });
    }
  },[bar_values]);
  return t;
}

export default WeeklyValues;

// import React, {useEffect, useRef} from "react";

// import * as d3 from "d3";

// const WeeklyValues = (props) => {
//   var body = <div className="body"></div>;
//   const data = props.val;
//   console.log(props.val);
//   // useEffect(()=>{
//   var width = 20;
//   var height = 20;
//   const svg = d3
//     .select("body")
//     .append("svg")
//     .attr("width", width)
//     .attr("height", height);
//  svg.append("rect")
//  .data(data)
// 				.attr("x", (d,i)=>i*70)
// 				.attr("y", (d,i)=>height- d*10)
// 				.attr("width",9 )
// 				.attr("height",(d,i)=>(d*10)/2)
//   // },[])
//   return body
// };

// export default WeeklyValues;

// const data = props.val;

// const drawChart = (data) => {
//   const svg = d3
//     .select(".body")
//     .append("svg")
//     .attr("width", 20)
//     .attr("height", 50);
//   svg.selectAll("rect").data(data).enter().append("rect");
//   //  selection.attr("property", (d, i) => {})
//   console.log(data)
//   svg
//     .selectAll("rect")
//     .data(data)
//     .enter()
//     .append("rect")
//     .attr("x", (d, i) => i * 70)
//     .attr("y", (d,i)=>50-10*d)
//     .attr("width", 9)
//     .attr("height", (d, i) => d*10)
//     .attr("fill", "green");
// };

// useEffect(() => {
//   drawChart(data);
// },[]);

// console.log(data);

import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBell,
  faBuilding,
  faCalendar,
  faCalendarAlt,
} from "@fortawesome/free-regular-svg-icons";
import {faCity, faUser} from "@fortawesome/free-solid-svg-icons";
import "./ConsumerMetric.css";
import ConsumerBarGraphs from "./ConsumerBarGraphs";
import ConsumerPieChart from "./ConsumerPieChart";
import image from "/Users/apple/CMS/cms-dashboard/src/components/images/products.png"
const ConsumerMetricBoxes = () => {
  return (
    <div className="boxes">
      <div className="show">Showing | All Categories | All Products</div>
      <div className="consumer">
        <div className="box">
          <div className="dataCategory">Users Profiled</div>
          <div className="value">
            <div className="det">
              <b>16.5K</b>
              <p></p>
            </div>
            <div className="figure">
              <FontAwesomeIcon icon={faUser} />
            </div>
          </div>
        </div>
        <div className="box">
          <div className="dataCategory">Living in</div>
          <div className="value">
            <div className="det">
              <b>638 </b>
              <p>Cities</p>
            </div>
            <div className="figure">
              <FontAwesomeIcon icon={faCity} />
            </div>
          </div>
        </div>
        <div className="box">
          <div className="dataCategory">Products Experienced</div>
          <div className="value">
            <div className="det">
              <b>1.52</b>
              <p>PerUser</p>
            </div>
            <div className="figure"><img src={image} alt="image" width="32px" /></div>
          </div>
        </div>
        <div className="box">
          <div className="dataCategory">Profiling Period</div>
          <div className="value">
            <div className="det">
              <div>
              <b>7</b>
              </div>
              <p>Days</p>
            </div>
            <div className="figure">
              <FontAwesomeIcon icon={faCalendarAlt} />
            </div>
          </div>
        </div>
      </div>
      <ConsumerBarGraphs/>
      <ConsumerPieChart/>
    </div>
  );
};

export default ConsumerMetricBoxes;

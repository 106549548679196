import React from 'react'
import ProductGender from '../ProductGender/ProductGender'
import "./ProductGeography.css"


const data = [
    {
        state: "MAHARASHTRA",
        trial_rate: 0.5579,
        trial_units: 1.67,
        repeat: 1.56,
        frequency: 1.06,
        repeat_unit: 1.88,

    },
    {
        state: "UTTAR PRADESH",
        trial_rate: 0.4176,
        trial_units: 1.66,
        repeat: 2.18,
        frequency: 1.06,
        repeat_unit: 1.7,

    },
    {
        state: "WEST BENGAL",
        trial_rate: 0.3085,
        trial_units: 1.75,
        repeat: 2.06,
        frequency: 1.07,
        repeat_unit: 1.88,

    },
    {
        state: "DELHI",
        trial_rate: 0.3257,
        trial_units: 1.57,
        repeat: 2.18,
        frequency: 1.07,
        repeat_unit: 1.62,

    },
    {
        state: "KARNATAKA",
        trial_rate: 0.2708,
        trial_units: 1.62,
        repeat: 2.5,
        frequency: 1.24,
        repeat_unit: 1.54,

    },
]

export default function ProductGeography() {
    return (
        <div>
            <div className='geographySection'>
                <div className='geographyContainer'>
                    <div className='geographyHeader'>
                        <b>Geography</b>
                    </div>
                    <div>
                        <div className='productCompare'>
                            Compare States
                        </div>
                    </div>
                </div>
                <div className='states'>
                    <table className='productTable'>
                        <tr className='productHeadings'>
                            <th>
                                States
                            </th>
                            <th colspan="2">
                                Trails
                            </th>
                            <th colSpan="4">
                                Adoption
                            </th>

                        </tr>
                        <tr className='productSections'>
                            <td>

                            </td>
                            <td>
                                Trail Rate
                            </td>
                            <td>
                                Trial Units
                            </td>
                            <td>
                                Trial Rate
                            </td>
                            <td>
                                Repeat
                            </td>
                            <td>
                                Frequency
                            </td>
                            <td>
                                Repeat Unit
                            </td>
                        </tr>
                        {
                            data.map(d => {
                                return <>
                                    <tr className='productDetails'>
                                        <td className='productStates'>
                                            {d.state}
                                        </td>
                                        <td>
                                            {d.trial_rate}%
                                        </td>
                                        <td>
                                            {d.trial_units}
                                        </td>
                                        <td>
                                            {d.trial_rate}%
                                        </td>
                                        <td>
                                            {d.repeat}%
                                        </td>
                                        <td>
                                            {d.frequency}
                                        </td>
                                        <td>
                                            {d.repeat_unit}
                                        </td>
                                    </tr>
                                </>
                            })
                        }

                    </table>
                </div>
            </div>
            <ProductGender />
        </div>
    )
}

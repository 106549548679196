import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowUpShortWide, faArrowDownShortWide } from "@fortawesome/free-solid-svg-icons"



const ProductWiseSummary = (props) => {
  const brandId = props.brandId;
  const startDate = props.startDate
  const endDate = props.endDate
  const [productDetail, setProductDetail] = useState([]);
  // let name = "Product Name"
  // console.log(productDetail[0])

  const [sortState, setSortState] = useState(false)

  const clickHandler = () => {
    setSortState(!sortState)
  }

  const getProductDetails = async () => {
    const response = await axios
      .get(
        `https://brand.smytten.com/brandsdashboard/master/brand/${brandId}/productwise_summary/`
      )
      .catch((err) => {
        console.log(err);
      });
    setProductDetail(response.data.map((dat) => dat));
    console.log(
      "productDetails",
      response.data.map((dat) => dat)
    );
    // dispatch(setUsers(response.data));
  };
  useEffect(() => {
    getProductDetails();
  }, [brandId]);
  return (
    <div className="mainTable">
      <div className="tableHeader">
        <h3>Product wise summary</h3>
        <div className="sort">
          <div>
            <p className="productSortSection" onClick={clickHandler}> <FontAwesomeIcon icon={faArrowUpShortWide} />sort by</p>
            {
              sortState ? <div className="sortProductSection">
                <div className="sortlabel"><FontAwesomeIcon icon={faArrowDownShortWide} /><FontAwesomeIcon icon={faArrowUpShortWide} /> Product Name</div>
                <div className="sortlabel"><FontAwesomeIcon icon={faArrowDownShortWide} /><FontAwesomeIcon icon={faArrowUpShortWide} /> Sub-Category</div>
                <div className="sortlabel"><FontAwesomeIcon icon={faArrowDownShortWide} /><FontAwesomeIcon icon={faArrowUpShortWide} /> Engagement</div>
                <div className="sortlabel"><FontAwesomeIcon icon={faArrowDownShortWide} /><FontAwesomeIcon icon={faArrowUpShortWide} /> Consideration</div>
                <div className="sortlabel"><FontAwesomeIcon icon={faArrowDownShortWide} /><FontAwesomeIcon icon={faArrowUpShortWide} /> Samples Tried</div>
                <div className="sortlabel"><FontAwesomeIcon icon={faArrowDownShortWide} /><FontAwesomeIcon icon={faArrowUpShortWide} /> Conversion %</div>
                <div className="sortlabel"><FontAwesomeIcon icon={faArrowDownShortWide} /><FontAwesomeIcon icon={faArrowUpShortWide} /> Intent %</div>

              </div> : ""
            }
          </div>
          <p> Export</p>
        </div>
      </div>
      <div className="table">
        <div className="tableHeadings">
          <div>S.NO</div>
          <div>Product Name</div>
          <div>Sub-Category</div>
          <div>Engagement</div>
          <div>Consideration</div>
          <div>Samples Tried</div>
          <div>Conversion %</div>
        </div>

        {productDetail.map((d, index) => {
          return (
            <div className="values">

              <div className="tableValues">
                <div>{index + 1}</div>
                <div>{d.product_name}</div>
                <div>{d.sub_category}</div>
                <div>{d.engagement}</div>
                <div>{d.consideration}</div>
                <div>{d.samples_tried}</div>
                <div>{d.conversion}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProductWiseSummary;

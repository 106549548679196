import React from 'react';
import BrandVsCategory from './BrandVsCategory';
import MaleVsFemale from './MaleVsFemale';
import ProductWiseSummary from './ProductWiseSummary';
import StateWiseSummary from './StateWiseSummary';

const Maps = (props) => {
    // let brandid = props.brandId

    return (
        <div className='productWithMap'>
        <div className='maps'>
            <BrandVsCategory brandId={props.brandId} startDate={props.startDate} endDate={props.endDate}/>
            <MaleVsFemale brandId={props.brandId} startDate={props.startDate} endDate={props.endDate}/>
        </div>
        <div  className='indiaMap1'>
        <StateWiseSummary brandId = {props.brandId} startDate={props.startDate} endDate={props.endDate}/>
        </div>
        
        <ProductWiseSummary brandId={props.brandId} startDate={props.startDate} endDate={props.endDate}/>
        
        </div>
    );
};

export default Maps;
import React from 'react';
import ConsumerUsersTried from './ConsumerUsersTried';
import "./ConsumerBarGraphs.css"
import ConsumerUsersInterested from './ConsumerUsersInterested';

const ConsumerBarGraphs = () => {
    return (
        <div className='consumerCharts'>
            <ConsumerUsersTried/>
            <ConsumerUsersInterested/>
        </div>
    );
};

export default ConsumerBarGraphs;
import React, {useEffect, useRef, useState, useLayoutEffect} from "react";
import * as d3 from "d3";
import axios from "axios";
const Graph3 = (props) => {
  let bar_values = props.val
  let brandId = props.brandId
  let startDate = props.startDate;
  let endDate = props.endDate;
   const [boxData3, setBoxData3] = useState([]);
   const [barValues,setBarValues] = useState([])

  //  bar_values = boxData3[0].weekly_numbers.slice(0,7)

  // let bar_values


  
  // barValues.map(d=>{
  //   bar_values=d
  // })
  

  let string = props.colorData;

  // console.log(parseInt(string))

  let format = /[-]/;

  // var t = (
    
  // );
  const getPincodeServed = async () => {
    const response = await axios
      .get(
        `https://brand.smytten.com/brandsdashboard/master/brand/${brandId}/pincodes_serviced/?startDate=${startDate}&endDate=${endDate}`
      )
      .catch((err) => {
        console.log(err);
      });
    setBoxData3(response.data.map((dat) => dat));
    setBarValues(response.data.map((dat=>dat.weekly_numbers)))
    console.log(
      "products   graph3",
      response.data.map((dat) => dat)
    );
    console.log("barval",bar_values)
    // dispatch(setUsers(response.data));
  };
  useEffect(()=>{
    getPincodeServed()
  },[brandId,startDate,endDate])

  useEffect(() => {
    var canvas_width = parseInt(d3.select(".miniGraph3").style("width"));
    var canvas_height = parseInt(d3.select(".miniGraph3").style("height"));

    var margin = 0.05 * canvas_width;

    var graph_width = canvas_width + margin ;
    var graph_height = canvas_height - 2 * margin;

    var scale = d3
      .scaleLinear()
      .domain([Math.min(...bar_values), Math.max(...bar_values)])
      .range([graph_height / 2, graph_height / 1.3]);

    var partition_size = graph_width / bar_values.length;

    const svg = d3.selectAll(".svg-canvas3");
    // svg.selectAll("svg").remove();

    var bars = svg
      .selectAll("rect")
      .data(bar_values)
      .enter()
      .append("g")
      .attr("transform", function (d, i) {
        return `translate(${partition_size * i}, 0)`;
      });

    let rs = format.test(string);

    if (rs === true) {
      bars
        .append("rect")
        .attr("height", function (d) {
          return scale(d);
        })
        .attr("width", 9)
        .style("fill", "rgb(255, 115, 97)")
        .attr("y", function (d) {
          return graph_height - scale(d);
        });
    } else {
      bars
        .append("rect")
        .attr("height", function (d) {
          return scale(d);
        })
        .attr("width", 9)
        .style("fill", "rgb(0, 188, 138)")
        .attr("y", function (d) {
          return graph_height - scale(d);
        });
    }
  },[bar_values,brandId,startDate,endDate]);
  return (
    <div className="miniGraph3">
      <svg className="svg-canvas3" width="250px" height="40px" />
    </div>
  )
};

export default Graph3;

import React, {useState, useEffect} from "react";
import DatamapsIndia from "react-datamaps-india";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faArrowUpShortWide,faArrowDownShortWide} from "@fortawesome/free-solid-svg-icons"



const StateWiseSummary = (props) => {
  let states = {};
  const brandId = props.brandId;
  const startDate = props.startDate
  const endDate = props.endDate
  const [data, setData] = useState([]);
  const [statesList, setStatesList] = useState({});

  const [sortState,setSortState] = useState(false)

  // function toPascalCase(str) {
  //   return (" " + str).toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => {
  //     return chr.toUpperCase();
  //   });
  // }
  // let states = {}

  const clickHandler = () =>{
    setSortState(!sortState)
  }

  function nFormatter(num) {
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
    }
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
    }
    return num;
  }

  const getStateDetails = async () => {
    const response = await axios
      .get(
        `https://brand.smytten.com/brandsdashboard/master/brand/${brandId}/statewise_summary/?startDate=${startDate}&endDate=${endDate}`
      )
      .catch((err) => {
        console.log(err);
      });
    setData(response.data.map((dat) => dat));

    // response.data.map((dat) => {
    //   states[toTitles(dat.state_name)] = {value: dat.total_users_sampled};
    // });
    setStatesList(states);
    console.log(statesList);
    console.log(
      "productDetails",
      response.data.map((dat) => dat)
    );
    // dispatch(setUsers(response.data));
  };
  useEffect(() => {
    getStateDetails();
  }, [brandId,startDate,endDate]);

  function toTitles(s) {
    return s.replace(/\w\S*/g, function (t) {
      return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase();
    });
  }
  data.map((dat) => {
    states[toTitles(dat.state_name)] = {value: dat.total_users_sampled};
  });
  return (
    <>
      <div className="stateSummaryHeading">
        <h3>Heat Map</h3>
        <div className="stateSortSection">
        <select>
          <option value="All States">All States</option>
        </select>
        <div>
        <p className="sortStatesHeatMap" onClick={clickHandler}><FontAwesomeIcon icon={faArrowUpShortWide}/>sort by</p>
        {
          sortState ? <div className="sortStateSection">
            <div className="sortlabel"><FontAwesomeIcon icon={faArrowDownShortWide}/><FontAwesomeIcon icon={faArrowUpShortWide}/> State</div>
            <div className="sortlabel"><FontAwesomeIcon icon={faArrowDownShortWide}/><FontAwesomeIcon icon={faArrowUpShortWide}/> Total users Sampled</div>
            <div className="sortlabel"><FontAwesomeIcon icon={faArrowDownShortWide}/><FontAwesomeIcon icon={faArrowUpShortWide}/> Total Products Sampled</div>
            <div className="sortlabel"><FontAwesomeIcon icon={faArrowDownShortWide}/><FontAwesomeIcon icon={faArrowUpShortWide}/> NPS Rating</div>
          </div> : ""
        }
        </div>
        </div>
      </div>
      <div className="indiaMap">
        <div className="dataMap">
          <DatamapsIndia
            style={{width: 100, border: "2px solid white"}}
            regionData={states}
            hoverComponent={({value}) => {
              return (
                <div>
                  <div>
                    <p>{value.name}</p>
                    {value.value ? value.value : 0}
                  </div>
                </div>
              );
            }}
            mapLayout={{
              // legendTitle: "Schedules",
              startColor: "rgb(170, 211, 228)",
              endColor: "rgb(88, 114, 183)",
              hoverTitle: "Count",
              noDataColor: "rgb(170, 211, 228)",
              borderColor: "gray",
              hoverBorderColor: "#f5f5f5",
              hoverColor: "rgb(88, 114, 183)",
              // height: 20,
              // weight: 30,
            }}
          />
        </div>
        <div className="stateDetails">
          <div className="stateHeaders">
            <div className="state serialNumber"></div>
            <div className="stateName"></div>
            <div className="state UserSampled">Total users Sampled</div>
            <div className="state ProductSampled">Total Products Sampled</div>
            <div className="state NPSRating">NPS Rating</div>
          </div>
          <div className="stateValues">
            {data.map((dat, index) => {
              return (
                <div className="stateData">
                  <div className="state serialNumber">{index + 1}</div>
                  <div className="stateName">{dat.state_name}</div>
                  <div className="state UserSampled">
                    {nFormatter(dat.total_users_sampled)}
                  </div>
                  <div className="state ProductSampled">
                    {nFormatter(dat.total_products_sampled)}
                  </div>
                  <div className="state NPSRating">
                    {dat.nps_rating.toFixed(2)}%
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default StateWiseSummary;

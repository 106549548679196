import React from "react";
import {Chart} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {Bar} from "react-chartjs-2";

ChartJS.register(
  ChartDataLabels,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const userData = [
  {
    sub_categories: "Face Wash",
    value: 22000,
  },
  {
    sub_categories: "Face cream",
    value: 2000,
  },
  {
    sub_categories: "moisturizer",
    value: 8000,
  },
  {
    sub_categories: "Face pack",
    value: 3450,
  },
  {
    sub_categories: "shampoo",
    value: 20000,
  },
  {
    sub_categories: "sunscreen",
    value: 18000,
  },
];
const datas = {
  labels: userData.map((text) => text.sub_categories),
  datasets: [
    {
      label: "users tried",
      data: userData.map((text) => text.value),
      backgroundColor: "rgb(0, 48, 135)",
      barThickness: 30,
    },
  ],
};

function nFormatter(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
}
const ConsumerUsersTried = () => {
  return (
    <div className="barChartUsersTried">
      <div className="usersTried">
        <b>Users who Tried your product also tried</b>
      </div>
      <Bar
        data={datas}
        height={200}
        options={{
          plugins: {
            title: {
              display: true,
              text: "Top 6 Sub-Categories",
              position: "bottom",
            },
            legend: {
              display: false,
              position: "bottom",
            },
            datalabels: {
              formatter: function (value, context) {
                // return context.chart.data.labels[context.dataIndex];
                const datapoints = context.chart.data.datasets[0].data;
                function totalSum(total, datapoint) {
                  return total + datapoint;
                }
                const totalValue = datapoints.reduce(totalSum, 0);
                const percentageValue = ((value / totalValue) * 100).toFixed(1);

                return `${percentageValue}%`;
              },
              anchor: "end",
              align: "top",
              color: "#003087",
            },
          },
          scales: {
            y: {
              ticks: {
                callback: function (val, index) {
                  return nFormatter(val);
                },
              },
            },
          },
        }}
      />
    </div>
  );
};

export default ConsumerUsersTried;
